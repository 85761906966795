import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { useDeleteDatabaseMicroserviceMutation } from 'api/dbMicroserviceSlice';
import { IApplicationMicroserviceDatabase } from 'types/application/microservice/IDatabaseMicroservice';

const DangerZone: React.FC<{
  microservice: IApplicationMicroserviceDatabase
}> = ({ microservice }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteDbMicroservice, { isLoading }] = useDeleteDatabaseMicroserviceMutation();

  return (
    <>
      <ConfirmationModal
        show={!!showDeleteModal}
        title={translate('confirmDeletion')}
        confirmationText={translate('confirmDeleteDatabaseMicroservice', { name: microservice.name })}
        disabled={isLoading}
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={async () => await deleteDbMicroservice(microservice.microserviceable)}
        successMessage={translate('queuedForRemoval', { name: microservice.name })}
      />
      <Card bg="dark">
        <Card.Body>
          <Button
            variant="danger"
            onClick={() => setShowDeleteModal(true)}
          >
            {translate('deleteApplicationMicroservice')}
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default DangerZone;
