import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FC, useState } from 'react';
import { Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { IContainerLiveViewData } from 'types/application/microservice/IContainerLiveViewData';
import { getAgeInGoFormat } from 'helpers/datetime';
import { ContainerStatus } from 'kubernetes-types/core/v1';
import useStreamMicroservicePodStatus from 'hooks/useStreamMicroservicePodStatus';

const ContainerLiveView: FC<{ microservice: IBaseApplicationMicroservice }> = ({ microservice }) => {
  const [liveViewOpen, setLiveViewOpen] = useState(false);
  const {
    data: pods,
    error,
  } = useStreamMicroservicePodStatus(microservice);

  return (
    <Col>
      <strong>Live View</strong>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="right"
        rootClose
        overlay={(
          <Popover>
            <Popover.Body>
              {translate('containerLiveViewExplanation')}
            </Popover.Body>
          </Popover>
        )}
      >
        <
          span className="ms-2"><FontAwesomeIcon icon="info-circle" /></span>
      </OverlayTrigger>
      <FontAwesomeIcon
        icon={liveViewOpen ? 'chevron-up' : 'chevron-down'}
        className="ms-2"
        style={{ cursor: 'pointer' }}
        onClick={() => setLiveViewOpen(!liveViewOpen)}
      />
      {
        liveViewOpen && (
          <Card bg="dark" className="mt-2">
            <Card.Body>
              {
                !pods && !error && (<Spinner />)
              }
              {
                error && (
                  <Row>
                    <Col>
                      <p className="my-2 text-center text-danger">
                        <FontAwesomeIcon icon="engine-warning" />
                        <span className="ms-2">{translate('liveContainerDataError')}</span>
                      </p>
                    </Col>
                  </Row>
                )
              }
              {
                !error && pods && pods.length !== 0 && (
                  <Row className="mb-2">
                    <Col md={5}>Container</Col>
                    <Col>{translate('version')}</Col>
                    <Col>{translate('ready')}</Col>
                    <Col>{translate('status')}</Col>
                    <Col>{translate('restarts')}</Col>
                    <Col>{translate('age')}</Col>
                  </Row>
                )
              }
              {
                !error && pods && pods.length === 0 && (
                  <Row>
                    <Col>
                      <p className="my-2 text-center text-warning">{translate('noRunningContainersFound')}</p>
                      <p className="my-2 text-center text-warning">{translate('reachOutToSupport')}</p>
                    </Col>
                  </Row>

                )
              }
              {
                pods && pods.map((pod: IContainerLiveViewData) => {
                  if ('containerStatuses' in pod.status &&
                    (typeof pod.status.containerStatuses !== 'undefined')
                  ) {
                    return (
                      <Row key={pod.metadata.name}>
                        <Col md={5}>{pod.metadata.name}</Col>
                        <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{pod.status.containerStatuses[0].image.split(':')[1]}</Col>
                        <Col>{pod.status.containerStatuses.reduce((acc: number, curr: ContainerStatus) => curr.ready ? acc += 1 : acc, 0)} / {pod.status.containerStatuses.length}</Col>
                        <Col>{pod.metadata.deletionTimestamp ? translate('terminating') : pod.status.phase}</Col>
                        <Col>{pod.status.containerStatuses[0].restartCount}</Col>
                        <Col>{getAgeInGoFormat(pod.status.startTime as string)}</Col>
                      </Row>
                    )
                  }
                })
              }
            </Card.Body>
          </Card>
        )
      }
    </Col>
  );
};

export default ContainerLiveView;
