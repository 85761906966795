import { AnsiUp } from 'ansi_up';
import { FC, useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Col, Container, Dropdown, DropdownButton, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { IApplicationMicroserviceComputeBound, IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStreamMicroservicePodStatus from 'hooks/useStreamMicroservicePodStatus';
import { IContainerLiveViewData } from 'types/application/microservice/IContainerLiveViewData';
import { ContainerStatus } from 'kubernetes-types/core/v1';
import useStreamPodLogs from 'hooks/useStreamPodLogs';
import PreviousLogsModal from './PreviousLogsModal';

const LogsTab: FC<{
  microservice: IApplicationMicroserviceComputeBound;
}> = ({ microservice }) => {
  const ansiup = new AnsiUp();
  const [selectedPod, setSelectedPod] = useState<string>('');
  const logBody = useRef<HTMLDivElement>(null);
  const [showPrevious, setShowPrevious] = useState<boolean>(false);
  const [follow, setFollow] = useState<boolean>(true);
  const [pretty, setPretty] = useState<boolean>(true);
  const [timestamps, setTimestamps] = useState<boolean>(true);
  const {
    data: pods,
    error: podsError,
  } = useStreamMicroservicePodStatus(microservice as IBaseApplicationMicroservice);

  const {
    data: logs,
  } = useStreamPodLogs(
    microservice as IBaseApplicationMicroservice,
    selectedPod,
    {
      follow,
      pretty,
      timestamps,
    }
  );

  useEffect(() => {
    if (logBody && logBody.current) {
      // scroll bottom of logs body into view as it expands
      logBody.current.scrollIntoView();

      // keep logs scrolled to bottom as new messages come in
      const scrollHeight = logBody.current?.scrollHeight;
      logBody.current.scrollTop = scrollHeight;
    }
  }, [logs]);

  const prettifyName = (podName: string): string => {
    const chunks = podName.split('-');
    let env = chunks[0];
    if (env !== 'dev' && env !== 'staging') env = 'prod';
    const deploymentId = chunks[chunks.length - 2];
    const podId = chunks[chunks.length - 1];

    return `${env} ${deploymentId}-${podId}`;
  };

  if (podsError) return <Error />;
  const hasRestart = (selectedPod !== '') && !!pods.find((pod: IContainerLiveViewData) => {
    const result = pod.metadata.name === selectedPod
      && pod.status.containerStatuses
        ?.find((containerStatus: ContainerStatus) => containerStatus.restartCount > 0);

    return !!result;
  });

  return (
    <Container className="mb-4">
      {
        pods && pods.length > 0 ? (
          <>
            {
              showPrevious && (
                <PreviousLogsModal
                  microservice={microservice}
                  podName={selectedPod}
                  handleClose={() => setShowPrevious(false)}
                />
              )
            }
            <Row>
              <Col md={6}>
                <DropdownButton
                  variant="secondary"
                  menuVariant="dark"
                  title={selectedPod ? prettifyName(selectedPod) : translate('selectAPod')}
                  className="d-inline-block"
                  onSelect={(e) => setSelectedPod(e as string)}
                >
                  {
                    pods.map((pod: IContainerLiveViewData) => (
                      <Dropdown.Item
                        key={pod.metadata.name}
                        eventKey={pod.metadata.name}
                        active={selectedPod === pod.metadata.name}
                      >
                        {prettifyName(pod.metadata.name as string)}
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              </Col>
            </Row>
            {
              hasRestart && (
                <Row className="mt-3">
                  <Col>
                    <Button onClick={() => setShowPrevious(true)}>
                      {translate('previousContainer')}
                    </Button>
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="right"
                      overlay={(
                        <Popover>
                          <Popover.Body>
                            {translate('previousContainerLogExplanation')}
                          </Popover.Body>
                        </Popover>
                      )}
                    >
                      <Badge pill bg="secondary" className="ms-1">
                        <FontAwesomeIcon icon="info" />
                      </Badge>
                    </OverlayTrigger>
                  </Col>
                </Row>
              )
            }
            <Row className="mt-3">
              <Col md={6}>
                <Row>
                  <Col>
                    <h5>Log Options</h5>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Follow"
                      checked={follow}
                      onChange={() => setFollow(!follow)}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Timestamps"
                      checked={timestamps}
                      onChange={() => setTimestamps(!timestamps)}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Pretty"
                      checked={pretty}
                      onChange={() => setPretty(!pretty)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                {
                  selectedPod ? (
                    <>
                      <Row className="my-2">
                        <Col>
                          <FontAwesomeIcon className="text-warning" icon="info-circle" />
                          <span className="text-warning ms-2">{translate('logsFromStdOutAndStdErr')}</span>
                        </Col>
                      </Row>
                      <Card bg="dark" style={{ minHeight: '25rem', maxHeight: '35rem' }}>
                        <Card.Body style={{ overflowY: 'scroll' }} ref={logBody}>
                          <pre>
                            {
                              logs.map((log: string, index: number) => {
                                return <div key={index} dangerouslySetInnerHTML={{ __html: ansiup.ansi_to_html(log) }} />;
                              })
                            }
                          </pre>
                        </Card.Body>
                      </Card>
                    </>
                  ) : (
                    <Card className="py-3" bg="dark">
                      <Card.Body>
                        <Row>
                          <Col className="text-warning text-center">
                            <FontAwesomeIcon icon="info-circle" />
                            <span className="ms-2">{translate('noPodSelected')}</span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                }
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col>
              <Card className="py-3" bg="dark">
                <Card.Body>
                  <Row>
                    <Col className="text-warning text-center">
                      <FontAwesomeIcon icon="info-circle" />
                      <span className="ms-2">{translate('noPodsFound')}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      }
    </Container>
  );
};

export default LogsTab;
