import { slugify } from 'helpers/slugify';
import { useEffect, useState } from 'react';
import { IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { IContainerLiveViewData } from 'types/application/microservice/IContainerLiveViewData';

const useStreamMicroservicePodStatus = (microservice: IBaseApplicationMicroservice) => {
  const [pods, setPods] = useState<IContainerLiveViewData[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (microservice.application) {
      const sse = new EventSource(
        `${window.location.origin}/proxy/pods/${microservice.application?.namespace}/${slugify(microservice.name)}/stream`,
        { withCredentials: true }
      );

      sse.onmessage = e => {
        setPods(JSON.parse(e.data));
      }
      sse.onerror = () => {
        setError(true);
        sse.close();
      }
      return () => {
        sse.close();
      };
    } else {
      setError(true);
    }
  }, []);

  return {
    data: pods,
    error,
  };
}

export default useStreamMicroservicePodStatus;
