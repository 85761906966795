import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { IApplicationMicroserviceFilesystem } from 'types/application/microservice/IFilesystemMicroservice';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { useDeleteFilesystemMicroserviceMutation } from 'api/filesystemMicroserviceSlice';

const DangerZone: React.FC<{
  microservice: IApplicationMicroserviceFilesystem
}> = ({ microservice }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteFilesystemMicroservice, { isLoading }] = useDeleteFilesystemMicroserviceMutation();

  return (
    <>
      <ConfirmationModal
        show={!!showDeleteModal}
        title={translate('confirmDeletion')}
        confirmationText={translate('confirmDeleteFilesystemMicroservice', { name: microservice.name })}
        disabled={isLoading}
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={async () => await deleteFilesystemMicroservice(microservice.microserviceable)}
        successMessage={translate('queuedForRemoval', { name: microservice.name })}
      />
      <Card bg="dark">
        <Card.Body>
          <Button
            variant="danger"
            onClick={() => setShowDeleteModal(true)}
          >
            {translate('deleteApplicationMicroservice')}
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default DangerZone;
