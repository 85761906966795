import { useEffect, useState } from 'react';
import { IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import queryString from 'query-string';

type Options = {
  follow: boolean;
  pretty: boolean;
  timestamps: boolean;
  previous?: boolean;
}

const useStreamPodLogs = (microservice: IBaseApplicationMicroservice, podName: string | undefined = undefined, options: Options) => {
  const [logs, setLogs] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const parameters = queryString.stringify({
    containerName: microservice.helm_chart_name,
    ...options
  });

  useEffect(() => {
    if (microservice && podName && options) {
      const sse = new EventSource(
        `${window.location.origin}/proxy/pods/${microservice.application?.namespace}/${podName}/logs?${parameters}`,
        { withCredentials: true });
      sse.onmessage = e => {
        setLogs((prevLogs) => [...prevLogs, e.data]);
      }
      sse.onerror = () => {
        setError(true);
        sse.close();
      }
      return () => {
        sse.close();
      };
    }
  }, [podName, options.follow, options.pretty, options.timestamps]);

  return {
    data: logs,
    error,
  };
}

export default useStreamPodLogs;
