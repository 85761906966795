import React, { useState, useEffect } from 'react';
import { Accordion, Button, Card, Col, ListGroup, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useParams } from 'react-router-dom';
import Unauthorized from 'common/Unauthorized';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';
import { Badge } from 'react-bootstrap';
import { useEditMicroserviceS3AccessMutation } from 'api/applicationMicroserviceSlice';
import { useGetConsumesIamPolicyMicroservicesQuery } from 'api/applicationMicroserviceSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToast from 'hooks/useToast';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { IApplicationMicroserviceS3 } from 'types/application/microservice/IS3Microservice';
import { Microserviceable, MicroserviceType } from 'types/application/microservice/MicroserviceType';
import { IApplicationMicroservice, IApplicationMicroserviceComputeBound } from 'types/application/microservice/IApplicationMicroservice';

const S3AccessManagement: React.FC<({
  microservice: IApplicationMicroserviceS3;
  isDeveloperAuthorized: boolean;
})> = ({ microservice, isDeveloperAuthorized }) => {
  if (!isDeveloperAuthorized) {
    return <Unauthorized />;
  }

  const { applicationId } = useParams();
  const [addingMode, setAddingMode] = useState<boolean>(false);
  const [selected, setSelected] = useState<IApplicationMicroserviceComputeBound[]>([]);
  const [editMicroserviceS3Access, { isLoading: saveIsLoading }] = useEditMicroserviceS3AccessMutation();
  const { data: consumingMicroservices, isLoading: getConsumersIsLoading } = useGetConsumesIamPolicyMicroservicesQuery({ microserviceId: microservice.id });
  const toast = useToast();

  const { result } = useSearchApplicationMicroservices({
    applicationId,
    initialSearchObj: {
      exclude_type: ['s3', 'redis', 'database', 'auth', 'filesystem']
    },
  });

  useEffect(() => {
    setSelected([]);
  }, [consumingMicroservices])

  const { isLoading: searchMsIsLoading, data: allMicroservices } = result;
  const isLoading = saveIsLoading || getConsumersIsLoading || searchMsIsLoading;

  return (
    <Accordion defaultActiveKey="0">
      <Card className="bg-dark">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Col className="text-start">
            {translate('accessManagement')}
          </Col>
          <Col className="text-end">
            <AccordionToggleLink
              eventKey="0"
              inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
              activeText={translate('close')}
            />
          </Col>
        </Card.Header>
        <Accordion.Collapse eventKey="0" className="bg-dark">
          <Card.Body>
            {isLoading || !consumingMicroservices ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              <>
                <ListGroup className="mb-3">
                  {consumingMicroservices.map((
                    service: IApplicationMicroserviceComputeBound,
                  ) => (
                    <ListGroup.Item key={service.id} className="d-flex justify-content-between align-items-center bg-dark text-white">
                      <div>
                        <h5 className="d-inline">{service.name}</h5>
                        <Badge bg="secondary" className="ms-2">{service.application_microserviceable_type.toUpperCase()}</Badge>
                      </div>
                      <Button variant="danger" size="sm" onClick={async () => {
                        await editMicroserviceS3Access({
                          microserviceId: microservice.id,
                          iamPolicyableMicroserviceIds: consumingMicroservices
                            .filter(ms => ms.id !== service.id)
                            .map(ms => ms.application_microserviceable_id),
                        })
                      }}>
                        <FontAwesomeIcon icon="trash-alt" />
                        <span className="ms-2">{translate('remove')}</span>
                      </Button>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                {addingMode ? (
                  <Form>
                    <Typeahead
                      multiple
                      id="microservice-search"
                      isLoading={searchMsIsLoading}
                      options={allMicroservices?.data || []}
                      labelKey="name"
                      placeholder={translate('selectMicroservice')}
                      onChange={(selectedItems) => {
                        setSelected(selectedItems as IApplicationMicroserviceComputeBound[]);
                      }}
                      selected={selected}
                      filterBy={(option) => {
                        return !selected
                          //@ts-expect-error stupid async option
                          .find((microService) => microService.id === option.id)
                          //@ts-expect-error stupid async option
                          && !consumingMicroservices.find(microService => microService.id === option.id);
                      }}
                      //@ts-expect-error stupid async option
                      renderMenuItemChildren={(option: IApplicationMicroservice<MicroserviceType, Microserviceable>) => {
                        return (
                          <div>
                            {option.name}
                            <div>
                              <small>
                                Type: {option.application_microserviceable_type}
                              </small>
                            </div>
                          </div>
                        );
                      }}
                    />
                  </Form>
                ) : (
                  <Button variant="primary" onClick={() => setAddingMode(true)}>
                    <FontAwesomeIcon icon='plus' />
                    <span className="ms-2">{translate('addMicroservice')}</span>
                  </Button>
                )}
                <div className="mt-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setAddingMode(false);
                      setSelected([]);
                    }}
                    disabled={isLoading || !selected.length}
                  >
                    <FontAwesomeIcon icon="ban" />
                    <span className="ms-2">{translate('cancel')}</span>
                  </Button>
                  <Button
                    variant="primary"
                    className="ms-2"
                    disabled={isLoading || !selected.length}
                    onClick={async () => {
                      try {
                        await editMicroserviceS3Access({
                          microserviceId: microservice.id,
                          iamPolicyableMicroserviceIds: [
                            ...selected.map(ms => ms.application_microserviceable_id),
                            ...consumingMicroservices.map(ms => ms.application_microserviceable_id),
                          ]
                        })

                        toast({
                          bg: 'success',
                          title: translate('updated'),
                          message: translate('savedSuccessfully'),
                          autohide: true,
                        });
                      } catch (error) {
                        toast({
                          bg: 'danger',
                          title: translate('error'),
                          message: translate('anUnexpectedErrorOccurred'),
                          autohide: false,
                        });
                      } finally {
                        setAddingMode(false);
                      }
                    }}
                  >
                    {/** @ts-expect-error stupid icon */}
                    <FontAwesomeIcon icon={isLoading ? 'spinner' : 'floppy-disk'} />
                    <span className="ms-2">{translate('save')}</span>
                  </Button>
                </div>
              </>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default S3AccessManagement;
