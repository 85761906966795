import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useGetApplicationMicroserviceQuery } from 'api/applicationMicroserviceSlice';
import ApiDetail from './ApiDetail';
import AuthDetail from './AuthDetail';
import RelationalDatabaseDetail from './RelationalDatabaseDetail';
import HasuraDetail from './HasuraDetail';
import LambdaDetail from './LambdaDetail';
import RedisDetail from './RedisDetail';
import S3Detail from './S3Detail';
import ScheduledJobDetail from './ScheduledJobDetail';
import UiDetail from './UiDetail';
import WorkerDetail from './WorkerDetail';
import FilesystemDetail from './FilesystemDetail';
import { Col, Row } from 'react-bootstrap';
import { startCase } from 'lodash';

const Detail: React.FC = () => {
  const { microserviceId } = useParams();
  const { data: microservice, isFetching, error } = useGetApplicationMicroserviceQuery(microserviceId);

  if (error) return <Error coverPage />;
  if (isFetching && !microservice) return <Spinner size="10x" coverViewport />;
  let detailPage = null;

  switch (microservice.application_microserviceable_type) {
    case 'api': detailPage = <ApiDetail microservice={microservice} />; break;
    case 'auth': detailPage = <AuthDetail microservice={microservice} />; break;
    case 'database': detailPage = <RelationalDatabaseDetail microservice={microservice} />; break;
    case 'hasura': detailPage = <HasuraDetail microservice={microservice} />; break;
    case 'lambda': detailPage = <LambdaDetail microservice={microservice} />; break;
    case 'redis': detailPage = <RedisDetail microservice={microservice} />; break;
    case 's3': detailPage = <S3Detail microservice={microservice} />; break;
    case 'scheduled_job': detailPage = <ScheduledJobDetail microservice={microservice} />; break;
    case 'ui': detailPage = <UiDetail microservice={microservice} />; break;
    case 'worker': detailPage = <WorkerDetail microservice={microservice} />; break;
    case 'filesystem': detailPage = <FilesystemDetail microservice={microservice} />; break;
    default: detailPage = <p>{translate('notImplemented')}</p>;
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-secondary">
            {translate('microserviceType')}: {
              microservice.application_microserviceable_type === 's3'
                ? translate('objectStore')
                : startCase(microservice.application_microserviceable_type.replace('_', ' '))
            }
          </h4>
        </Col>
      </Row>
      {detailPage}
    </>
  )
};

export default Detail;
