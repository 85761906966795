import React, { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from '@apex/react-toolkit/components';
import { useListOauthScopesMutation } from 'api/authSlice';
import UpdatePreviewValues from 'common/application/ApplicationFormWithPreview/UpdatePreviewValues';
import FormFeedback from 'common/FormFeedback';

const textColorOptions = [
  {
    option: 'text-dark',
    display: 'Dark',
  },
  {
    option: 'text-light',
    display: 'Light',
  },
];

const ApplicationForm = ({ primaryAction, initialValues, disabled, header, onCancel, onSubmit, apiErrors, onFormValuesChange }) => {
  const [fetchOauthScopeScopes, { data: oauthScopes, isLoading }] = useListOauthScopesMutation();

  useEffect(() => {
    fetchOauthScopeScopes();
  }, [fetchOauthScopeScopes]);

  const schema = yup.object({
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().nullable(),
    scopes: yup.array().of(yup.string()),
    background_color: yup.string().required(translate('backgroundColorRequired')),
    text_color: yup.string().required(translate('textColorRequired')),
    icon: yup.string().required(translate('iconRequired')),
    service_mesh_active: yup.bool(),
  });

  if (!oauthScopes || isLoading) return (<Spinner />);

  return (
    <>
      {header && <h2>{header}</h2>}
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder="Name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{translate('description')}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formIcon">
              <Form.Label>{translate('icon')}</Form.Label>
              <Form.Control
                required
                name="icon"
                type="text"
                placeholder="Icon"
                value={values.icon}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.icon && !errors.icon}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="icon"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBackgroundColor">
              <Form.Label>{translate('backgroundColor')}</Form.Label>
              <Form.Control
                required
                name="background_color"
                type="color"
                placeholder={translate('backgroundColor')}
                value={values.background_color}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="background_color"
              />
            </Form.Group>

            <Form.Group controlId="formTextColor" className="mb-3">
              <Form.Label>{translate('textColor')}</Form.Label>
              <Form.Select
                required
                name="text_color"
                type="text"
                placeholder={translate('textColor')}
                value={values.text_color}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
              >
                {textColorOptions.map(({ option, display }) => (
                  <option key={option} value={option}>
                    {display}
                  </option>
                ))}

              </Form.Select>
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="text_color"
              />
            </Form.Group>

            <h3>{translate('infrastructure')}</h3>
            <Form.Group title={translate('infrastructure')} className="mb-3">
              <Form.Check
                onChange={handleChange}
                type="checkbox"
                label={translate('enableServiceMesh')}
                value={values.service_mesh_active}
                onBlur={handleBlur}
                checked={values.service_mesh_active}
                name="service_mesh_active"
              />
            </Form.Group>

            <h3>Oauth Scopes</h3>
            {
              oauthScopes.map(({ id, description }) => (
                <Form.Group key={id} title={description}>
                  <Form.Check
                    onChange={handleChange}
                    type="checkbox"
                    label={id}
                    value={id}
                    onBlur={handleBlur}
                    checked={values.scopes.includes(id)}
                    name="scopes"
                  />
                </Form.Group>
              ))
            }

            <Form.Group className="mt-2">
              {onCancel && (
                <Button
                  type="button"
                  variant="secondary"
                  disabled={disabled}
                  onClick={onCancel}
                >
                  {translate('cancel')}
                </Button>
              )}

              {onSubmit && (
                <Button
                  type="submit"
                  variant="primary"
                  className="mx-2"
                  disabled={disabled}
                >
                  {primaryAction}
                </Button>
              )}
            </Form.Group>
            <UpdatePreviewValues onFormValuesChange={onFormValuesChange} />
          </Form>
        )}
      </Formik>
    </>
  );
};

ApplicationForm.defaultProps = {
  primaryAction: translate('save'),
  initialValues: {
    name: '',
    description: '',
    icon: 'square',
    background_color: '#43494F',
    text_color: 'text-light',
    scopes: [],
    service_mesh_active: true,
  },
  header: null,
  onCancel: null,
  onSubmit: null,
  apiErrors: null,
  onFormValuesChange: () => null,
};

ApplicationForm.propTypes = {
  primaryAction: PropTypes.string,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    background_color: PropTypes.string,
    text_color: PropTypes.string,
    icon: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string),
  }),
  apiErrors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
    background_color: PropTypes.arrayOf(PropTypes.string),
    text_color: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool.isRequired,
  header: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onFormValuesChange: PropTypes.func,
};

export default ApplicationForm;
