import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnsiUp } from 'ansi_up';
import useStreamPodLogs from 'hooks/useStreamPodLogs';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { IApplicationMicroserviceComputeBound, IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';

const PreviousLogsModal: React.FC<{
  microservice: IApplicationMicroserviceComputeBound;
  podName: string;
  handleClose: () => void;
}> = ({ microservice, podName, handleClose }) => {
  const ansiup = new AnsiUp();
  const {
    data: logs,
  } = useStreamPodLogs(
    microservice as IBaseApplicationMicroservice,
    podName,
    {
      follow: true,
      pretty: true,
      timestamps: false,
      previous: true,
    }
  );

  return (
    <Modal show size="xl">
      <Modal.Body className="confirm">
        <Card.Header>
          <Row>
            <Col className="text-center">
              <h3>
                {translate('logsFromPreviousContainer')}
              </h3>
            </Col>
          </Row>
        </Card.Header>
        <Card bg="dark">
          <Card.Body style={{ overflowY: 'scroll' }} >
            <Row>
              <Col className="text-center">
                <pre>
                  {podName}
                </pre>
              </Col>
            </Row>
            <pre>
              {
                logs.map((log: string, index: number) => {
                  return <div key={index} dangerouslySetInnerHTML={{ __html: ansiup.ansi_to_html(log) }} />;
                })
              }
            </pre>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          {translate('close')}
        </Button>
        <Button className="me-2" onClick={async () => {
          await navigator.clipboard.writeText(logs.join(''));
        }}>
          <FontAwesomeIcon className="me-1" icon="copy" />
          {translate('copy')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviousLogsModal;
