import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { camelCase } from 'lodash';
import CopyableLineItem from 'common/copyableLineItem';
import IDatabaseMicroservice, { IDatabaseMicroserviceMetadata } from 'types/application/microservice/IDatabaseMicroservice';
import Environment from 'types/Environment';
import TrustedSources from 'common/applicationMicroservice/relationalDatabase/RelationalDatabaseConnectionDetails/TrustedSources';
import AccordionToggleLink from 'common/AccordionToggleLink';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { useUpdateDatabasePasswordMutation } from 'api/dbMicroserviceSlice';

const RelationalDatabaseConnectionDetails: React.FC<{
  environment: Environment;
  instance: IDatabaseMicroserviceMetadata;
  engine: string;
  engineVersion: string;
  dbMicroservice: IDatabaseMicroservice;
  isDeveloperAuthorized: boolean;
}> = ({ environment, instance, engine, engineVersion, dbMicroservice, isDeveloperAuthorized }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [updateDbPassword, { isLoading }] = useUpdateDatabasePasswordMutation();
  const disabled = isLoading;

  return (
    <div className="mb-4">
      <Card bg="dark">
        <Card.Header>
          <Row>
            <Col md="3" className="text-start">
              {`${translate(environment)} ${translate('databaseConnectionDetails')}`}
            </Col>
            <Col>
              {
                instance.status ?
                  <span className="text-muted me-4">{engine} {engineVersion}: {instance.status}</span> :
                  <>
                    <span className="text-muted me-1">{engine} {engineVersion}:</span>
                    <span className="text-danger me-4">{translate('dbStatusNotAvailable')}</span>
                  </>
              }
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey={environment}
                inactiveText="View"
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey={environment}>
        <Card bg="dark">
          <Card.Body>
            <Row>
              <Col>
                {
                  Object.keys(instance as IDatabaseMicroserviceMetadata)
                    .filter(field => field !== 'status')
                    .map((field: string) => {
                      return (
                        field && <CopyableLineItem
                          key={field}
                          field={translate(camelCase(field))}
                          value={instance[field as keyof IDatabaseMicroserviceMetadata]?.toString() || `${field} ${translate('notAvailableYet')}`}
                          isReady={!!instance[field as keyof IDatabaseMicroserviceMetadata]}
                          buttonVariant="link"
                          buttonSize="sm"
                          secureField={field === 'master_password'}
                        />
                      );
                    })
                }
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <TrustedSources
                  environment={environment}
                  dbMicroservice={dbMicroservice}
                />
              </Col>
            </Row>
            {
            isDeveloperAuthorized && (
              <>
                <Row className="mt-4">
                  <Col>
                    <Button size="sm" onClick={() => setShowConfirmationModal(true)}>
                      {translate('updatePassword')}
                    </Button>
                  </Col>
                </Row>
                <ConfirmationModal
                  show={showConfirmationModal}
                  onConfirm={async () => updateDbPassword({ dbMsId: dbMicroservice.id, environment })}
                  handleClose={() => setShowConfirmationModal(false)}
                  disabled={disabled}
                  title={translate('updatePassword')}
                  confirmationText={translate('confirmUpdatePassword')}
                  successMessage={translate('passwordChangeQueuedSuccessfully')}
                />
              </>
            )
          }
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </div>
  );
};

export default RelationalDatabaseConnectionDetails;
