import { apiSlice } from 'api/apiSlice';
import IS3Microservice, { IS3MicroserviceWithMetadata } from 'types/application/microservice/IS3Microservice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addS3Microservice: builder.mutation<IS3Microservice, IS3Microservice>({
      query: (data: IS3Microservice) => ({ url: 's3-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    deleteS3Microservice: builder.mutation<null, IS3Microservice>({
      query: (data: IS3Microservice)  => ({ url: `s3-microservices/${data.id}`, method: 'delete', }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    getS3Microservice: builder.query<IS3MicroserviceWithMetadata, string>({
      query: (id: string) => ({ url: `s3-microservices/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationMicroservices', id }],
    }),
  }),
});

export const {
  useAddS3MicroserviceMutation,
  useDeleteS3MicroserviceMutation,
  useGetS3MicroserviceQuery,
} = extendedApiSlice;
