import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, Error } from "@apex/react-toolkit/components";
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import Configuration from 'App/AppRoutes/Applications/Application/Microservices/Detail/S3Detail/S3AccessManagement/Configuration';
import S3AccessManagement from './S3AccessManagement';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import MicroservicePipelineMonitor from 'common/applicationMicroservice/MicroservicePipelineMonitor';
import { IApplicationMicroserviceS3 } from 'types/application/microservice/IS3Microservice';
import { useGetS3MicroserviceQuery } from 'api/s3MicroserviceSlice';

const S3Detail: React.FC<{ microservice: IApplicationMicroserviceS3 }> = ({ microservice }) => {
  const { applicationId } = useParams();
  const { isDeveloperAuthorized, isSuperUser } = useIsApplicationMaintainer(applicationId);
  const { data: s3Microservice, isLoading, error } = useGetS3MicroserviceQuery(microservice.application_microserviceable_id);

  if (isLoading) return <Spinner size="10x" coverViewport />;
  if (error || !s3Microservice) return <Error coverPage />;

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>{microservice.name}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('deployedVersions')}:</strong>
              <ul>
                <li>{translate('dev')}: <pre className="d-inline">{s3Microservice?.dev_instance?.s3Url || translate('none')}</pre></li>
                <li>{translate('staging')}: <pre className="d-inline">{s3Microservice?.staging_instance?.s3Url || translate('none')}</pre></li>
                <li>{translate('prod')}: <pre className="d-inline">{s3Microservice?.prod_instance?.s3Url || translate('none')}</pre></li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <Configuration isDeveloperAuthorized={isDeveloperAuthorized} microservice={microservice} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} canPromote={isDeveloperAuthorized} />
          </Tab>
          {
            isDeveloperAuthorized && (
              <Tab eventKey="accessManagement" title={translate('accessManagement')}>
                <S3AccessManagement
                  microservice={microservice}
                  isDeveloperAuthorized={isDeveloperAuthorized}
                />
              </Tab>
            )
          }
          {
            isSuperUser && (
              <Tab eventKey="pipelines" title={translate('pipelines')}>
                <MicroservicePipelineMonitor microservice={microservice} />
              </Tab>
            )
          }
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

export default S3Detail;
